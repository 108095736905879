import React, {useEffect, useState} from 'react'
import {Table, Button, DatePicker, Form, Space, Tag, Modal, Input, message, InputNumber, Upload, Alert, Card, Descriptions, Popconfirm} from 'antd'
import axios from 'axios'
import moment from 'moment'
import fileDownload from 'js-file-download'
import locale from 'antd/es/date-picker/locale/de_DE'
import { Link, useParams } from 'react-router-dom'
import FormElement from '../forms/FormElement'
import dayjs from "dayjs";
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
} from '@ant-design/icons'

const { RangePicker } = DatePicker


const AufwendungTable = ({client, tableData = null as any}) => {

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox')
    const [selected, setSelected] = useState([] as any)
    const [data, setData] = useState(tableData || [] as any)
    const [planovo, setPlanovo] = useState([] as any)
    const [month, setMonth] = useState(dayjs())
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [modalData, setModalData] = useState(null as any)
    const [loading, setLoading] = useState(false)
    const [dokumente, setDokumente] = useState(null as any)
    const [link, setLink] = useState('')
    const [aForm] = Form.useForm()

    useEffect(() => {
        if (!tableData) getBuchungen()
        getVereinbarung()
        // console.log(month)
    }, [month])

    const getBuchungen = () => {
        axios.get(`/buchungen/client/${client._id}/${month}`)
            .then(data => {
                setData(data.data.docs)
                if (data.data.planovo) setPlanovo(data.data.planovo)
                // console.log(data.data)
            })
            .catch(error => console.log(error))
    }

    const showModal = (d) => {
        setModalData(d)
        setIsModalVisible(true)
        aForm.resetFields()
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const exportCSV = (versand = false) => {

        axios.post('/buchungen/pdf', {ehrenamt: client, aufwendungen: selected, month, document: dokumente.id, mailVersand: versand}, {responseType: 'blob'})
            .then(data => {
                if (!versand) fileDownload(data.data, `Aufwendungen_${month.format('YYYY-MM')}_${client.Vorname}${client.Nachname}_${client.Nummer}.pdf`)
                getBuchungen()
                setSelected([])
            })
            .catch(error => console.log(error))
    }

    const deleteBuchung = (id) => {
        axios.delete('/buchungen/delete/' + id)
            .then(data => getBuchungen())
            .catch(error => console.log(error))
    }

    const columns = [
        {
            title: 'Eingetragen am',
            dataIndex: '',
            width: 200,
            render: (d) => <Tag color='blue'>{moment(d.created_at).format('DD.MM.YYYY HH:mm')}</Tag>
        },
        {
            title: 'Planovo-Dienst',
            dataIndex: 'planovo',
            render: (_) => _ ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <Tag color='red'>Dienst nicht in Planovo vorhanden</Tag>
        },
        {
            title: 'Versendet',
            dataIndex: 'versendet',
            render: (_) => _ ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone  twoToneColor="#eb2f96"  />
        },
        {
            title: 'Zeitraum',
            dataIndex: '',
            width: 200,
            render: (d) => <>
                <div>{moment(d.zeit_von).format('DD.MM.YYYY HH:mm')} -</div>
                <div>{moment(d.zeit_bis).format('DD.MM.YYYY HH:mm')}</div>
            </>
        },
        {
            title: 'Stunden',
            dataIndex: '',
            width: 100,
            render: (d) => `${moment.duration(moment(d.zeit_bis).diff(d.zeit_von)).asHours().toFixed(2).toString().replace('.', ',')}`
        },
        {
            title: 'Tätigkeit',
            width: 500,
            dataIndex: 'text',
        },
        {
            title: 'Stundensatz',
            dataIndex: 'stundensatz',
            render: (d) => `${d.toFixed(2).toString().replace('.', ',')}`
        },
        {
            width: 300,
            render: (d) => (
                <>
                    <Button onClick={() => showModal(d)} className={'mr-2'}>Bearbeiten</Button>
                    <Button type="dashed" danger onClick={() => deleteBuchung(d._id)}>Löschen</Button>
                </>
            )
        }
    ]

    const columnsPlanovo = [
        {
            title: 'Schicht',
            dataIndex: 'schicht',
            width: 200
        },
        {
            title: 'Buchung',
            dataIndex: 'isGebucht',
            width: 200,
            render: (_) => _ ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone  twoToneColor="#eb2f96"  />
        },
        {
            title: 'Zeitraum',
            dataIndex: '',
            width: 200,
            render: (d) => <>
                <div>{d.from} -</div>
                <div>{d.to}</div>
            </>
        },
        {
            render: (d) => (
                <>
                    {!d.isGebucht && <Button onClick={() => showModal({zeit_von: moment(d.from, 'DD.MM.YYYY HH:mm:ss'), zeit_bis: moment(d.to, 'DD.MM.YYYY HH:mm:ss')})} className={'mr-2'}>In Buchung übernehmen</Button>}
                </>
            )
        }
    ]

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            // console.log(selectedRows.map((item: any) => item._id))

            setSelected(selectedRows)
        }
    }

    const onChange = (date: any) => {
        date && setMonth(dayjs(date))
    }

    const exportPDFVereinbarung = (mail = false) => {
        axios.post(`/ehrenamt/vereinbarung/pdf`, {ehrenamt: client, month, mail}, {responseType: 'blob'})
            .then(data => {
                if (!mail)
                    fileDownload(data.data, `Vereinbarung_${client.Vorname}${client.Nachname}_${client.Nummer}.pdf`)
                else
                    message.success('Erfolgreich versendet');
                getVereinbarung()
            })
            .catch(error => {
                message.error(JSON.stringify(error))
                console.log(error)
            })
    }

    const onFinish = (values: any) => {

        setLoading(true)

        const newData: any = {
            zeit_von: values.timerange[0].startOf('minute').format(),
            zeit_bis: values.timerange[1].startOf('minute').format(),
            text: values.text,
            stundensatz: values.stundensatz
        }

        if (modalData === null || !modalData._id) {

            newData['client'] = client.Nummer

            axios.post('/buchungen/erfassen', newData)
                .then(data => {
                    setIsModalVisible(false)
                    setModalData(null)
                    setLoading(false)
                    getBuchungen()
                })
                .catch(error => {
                    message.error('Fehlgeschlagen')
                    console.log(error)
                })

        } else {
            axios.put('/buchungen/' + modalData._id, newData)
                .then(data => {
                    setIsModalVisible(false)
                    setModalData(null)
                    setLoading(false)
                    getBuchungen()
                })
                .catch(error => {
                    message.error('Fehlgeschlagen')
                    console.log(error)
                })
        }

    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }

    const formTailLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8, offset: 6 },
    }

    const props = {
        maxCount: 1,
        onChange(info) {
            if (info.file.status === 'uploading' && !loadingUpload) {
                setLoadingUpload(true)
                const formData = new FormData()
                formData.append('file', info.file.originFileObj)
                formData.append('Typ', 'Vereinbarung')
                formData.append('Monat', moment(month.toISOString()).format('YYYY-MM'))

                axios.post(`/upload/${client._id}`, formData).then(item => {

                    message.success(`${info.file.name} wurde erfolgreich hinzugefügt.`)
                    setLoadingUpload(false)

                }).catch(err => console.error(err))
            }
        }
    }

    const getVereinbarung = () => {
        axios.get(`/ehrenamt/vereinbarung/${client._id}/${moment(month.toISOString()).format('YYYY-MM')}`).then((data) => {
            // console.log(data.data)
            setDokumente(data.data)

            if (data.data.status === 'COMPLETED') {
                downloadVereinbarung(data.data.id)
            } else {
                setLink('')
            }
        })
    }

    const downloadVereinbarung = (id) => {
        axios.get(`/ehrenamt/vereinbarung/${id}`)
            .then(data => {
                setLink(data.data.downloadUrl)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const createAccount = () => {
        axios.get(`/ehrenamt/account/${client._id}`)
            .then(data => {
                message.success(`Account wurde erstellt.`)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const downloadVereinbarung2 = () => {
        window.open(
            link,
            '_blank' // <- This is what makes it open in a new window.
        )
    }

    const deleteVereinbarung = () => {
        axios.delete(`/ehrenamt/vereinbarung/${dokumente.id}`)
            .then(data => {
                message.success(`Vereinbarung wurde gelöscht.`)
                getVereinbarung()
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <>
            {
                !tableData ? (
                    <Space className={'mb-3'}>
                        <DatePicker picker="month" onChange={onChange} defaultValue={month} />
                        <Button onClick={() => showModal(null)}>Neuer Eintrag</Button>
                        <Button onClick={() => exportPDFVereinbarung()}>Monatsvereinbarung</Button>
                        <Button onClick={() => exportPDFVereinbarung(true)} disabled={dokumente?.id}>Vereinbarung zum Unterschreiben generieren</Button>
                        <Button onClick={createAccount} disabled={client.User} >Erstelle Account</Button>
                    </Space>
                ) : (
                    <Space className={'mb-1 mt-5'}><h6 className={'text-uppercase font-weight-bold'}>{client?.Vorname} {client?.Nachname}</h6></Space>
                )
            }
            {
                dokumente?.status ? (
                    <Card className='mb-3'>
                    
                        <Tag 
                            color={dokumente.status !== 'COMPLETED' ? 'processing' : 'success'} 
                            bordered={false}>
                                {dokumente.status}
                        </Tag>

                        <Descriptions className='my-2' items={[
                            {
                                key: '1',
                                label: 'Unterzeichner 1',
                                children: dokumente.recipients[0].email
                            },
                            {
                                key: '2',
                                label: 'Status',
                                children:   <Tag 
                                                color={dokumente.recipients[0].signingStatus !== 'SIGNED' ? 'processing' : 'success'} 
                                                bordered={false}>
                                                    {dokumente.recipients[0].signingStatus}
                                            </Tag>
                            },
                            {
                                key: '3',
                                label: 'Link',
                                children: <a href={dokumente.recipients[0].signingUrl} target='_blank'>{dokumente.recipients[0].signingUrl}</a>
                            }
                        ]} />
                        <Descriptions items={[
                            {
                                key: '1',
                                label: 'Unterzeichner 2',
                                children: dokumente.recipients[1].email
                            },
                            {
                                key: '2',
                                label: 'Status',
                                children:   <Tag 
                                                color={dokumente.recipients[1].signingStatus !== 'SIGNED' ? 'processing' : 'success'} 
                                                bordered={false}>
                                                    {dokumente.recipients[1].signingStatus}
                                            </Tag>
                            },
                            {
                                key: '3',
                                label: 'Link',
                                children: <a href={dokumente.recipients[1].signingUrl} target='_blank'>{dokumente.recipients[1].signingUrl}</a>
                            }
                        ]} />

                        <div className='pt-3'>
                            <Button className='float-right' onClick={downloadVereinbarung2} disabled={!link.length}>Download Vereinbarung</Button>
                            
                            <Popconfirm
                                title="Vereinbarung löschen?"
                                onConfirm={deleteVereinbarung}
                                okText="Ja"
                                cancelText="Nein"
                            >
                                <Button className='float-right mr-2'danger type='link'>Löschen</Button>
                            </Popconfirm>
                        </div>
                    </Card>

                ) : (
                    <Alert message="Keine Vereinbarung versendet" type="error" className={'mb-3'} />
                )
            }

            <Table
                rowClassName={(record, index) =>
                    data.filter((time) => moment(time.zeit_von).isSame(moment(record.zeit_von), 'day')).length > 1 ? 'bg-warning' : '' ||
                    moment.duration(moment(record.zeit_bis).diff(record.zeit_von)).asHours() > 10 ? 'bg-info' : '' ||
                    !record.planovo ? 'bg-danger' : ''
                }
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                pagination={false}
                columns={columns}
                dataSource={data}
                rowKey={'_id'}
                footer={() => 
                <Space>
                    <Button type="primary" ghost onClick={() => exportCSV()}>PDF-Export</Button>
                    <Popconfirm
                        title="Die ausgewählten Einträge werden mit der Vereinbarung an die FiBu gesendet. Eine Kopie geht an den Ehrenamtlichen. E-Mail versenden?"
                        onConfirm={() => exportCSV(true)}
                        okText="Ja"
                        cancelText="Nein"
                    >
                        <Button className='float-right mr-2'danger disabled={!selected.filter((zeit: any) => !zeit.versendet).length}>Stunden zur Zahlung senden</Button>
                    </Popconfirm>
                </Space>
                }
            />

            <Table
                className={'mt-5'}
                pagination={false}
                columns={columnsPlanovo}
                dataSource={planovo}
                rowKey={'from'}
            />

            <Modal title="Eintrag bearbeiten" visible={isModalVisible} footer={false} onCancel={handleCancel} destroyOnClose={true}>
                <Form onFinish={onFinish} form={aForm} {...formItemLayout}>

                        <FormElement
                            //refData={refData}
                            formElement={{label: 'Deine Zeiten', name: 'timerange', type: 'rangeTime'}}
                            defaultValue={[dayjs(modalData?.zeit_von), dayjs(modalData?.zeit_bis)]}
                        />

                        <Form.Item
                            label={'Beschreibung'}
                            name="text"
                            initialValue={modalData?.text}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={'Stundensatz'}
                            name="stundensatz"
                            initialValue={modalData?.stundensatz}
                        >
                            <InputNumber min={0} decimalSeparator={','} step={0.25} />
                        </Form.Item>
                        <Button loading={loading} type={'primary'} htmlType={'submit'}>Speichern</Button>
                </Form>
            </Modal>
        </>
    )
}

export default AufwendungTable
